body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  text-align: center;
  padding: 20 px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 60px; /* Add padding to the bottom to make space for the footer */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 90vw; /* Use viewport width for max-width */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

textarea {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.character-count {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
}

.character-count.red {
  color: red;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.response-box {
  width: 100%;
  max-width: 90vw;
  height: 100px;
  margin-top: 10px;
  box-sizing: border-box;
}

.scale-graphic {
  margin-top: 20px;
  width: 100%;
  max-width: 90vw; /* Use viewport width for max-width */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.scale-bars {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.scale-bar {
  width: 20px;
  height: 20px;
  background-color: lightgray;
}

.scale-bar.active {
  background-color: green;
}

.App-footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  font-size: 12px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  margin-top: 0px; /* Add margin to the top to separate it from the content */
}

.spinner {
  margin-top: 20px;
  font-size: 18px;
  color: white;
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 10px;
  }

  form {
    width: 100%;
    padding: 0 10px;
  }

  textarea {
    font-size: 14px;
    height: 80px;
  }

  .response-box {
    height: 80px;
  }

  .scale-graphic {
    width: 100%;
  }

  .scale-bar {
    width: 15px;
    height: 15px;
  }

  .spinner {
    font-size: 16px;
  }
}